import React from "react";
import axios from "axios";

export const useHttp = () => {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    const request = async (url, method = "get", body, headers = {}) => {
        setLoading(true)

        let authEncrypted = localStorage.getItem("auth")
        let defaultHeaders = {
            "Authorization": "Basic " + authEncrypted
        }
        try {
            let response = await axios({
                method: method,
                url: url,
                data: body,
                headers: {...defaultHeaders, ...headers}
            })

            const data = await response.data
            if (!response.data) {
                return new Error((response.message && data.message) || "Что-то пошло не так")
            }
            setLoading(false)
            return data
        } catch (e) {
            let errorMessage = e.response?.data?.message
            setLoading(false)
            setError(errorMessage || e.message)
        }
    }

    const clearError = () => setError(null)

    return {loading, request, error, clearError}
}