import React from 'react';
import {Text} from "@consta/uikit/Text";
import {Table, TableTextFilter} from "@consta/uikit/Table";
import {Switch} from "@consta/uikit/Switch";
import {Badge} from "@consta/uikit/Badge";
import {Button} from "@consta/uikit/Button";
import {Sidebar} from "@consta/uikit/Sidebar";
import {useHistory, useParams} from "react-router-dom";
import {IconBackward} from "@consta/uikit/IconBackward";
import {Card} from "@consta/uikit/Card";
import {Avatar} from "@consta/uikit/Avatar";
import {useSelector} from "react-redux";
import {useHttp} from "../utils/hooks/http.hook";
import {useForm} from "react-hook-form";
import {ModalLoader} from "../utils/elements/LoaderModal";
import facebook from "../assets/img/facebook.png";


const adTypes = {
    "comp": "Компания",
    "group": "Группа",
    "ad": "Объявление",
}
let dateTime = 86400000
let date = new Date().getTime()
let today = new Date().toLocaleDateString() + " - " + new Date().toLocaleDateString()
let yesterday = new Date(date - dateTime).toLocaleDateString() + " - " + new Date(date - dateTime).toLocaleDateString()
let beforeYesterday = new Date(date - 2 * dateTime).toLocaleDateString() + " - " + new Date(date - 2 * dateTime).toLocaleDateString()
let threeDays = new Date(date - 3 * dateTime).toLocaleDateString() + " - " + new Date(date - dateTime).toLocaleDateString()
let week = new Date(date - 7 * dateTime).toLocaleDateString() + " - " + new Date(date - dateTime).toLocaleDateString()

const getColor = (item) => {
    if (item === null || item === 0 || item === 3 || item === "-") {
        return "#EB5757"
    } else if (item === 1) {
        return "#22C38E"
    } else if (item === 2) {
        return "#F38B00"
    } else if (item === 4) {
        return "#FFFF00"
    }
}

let reportStatuses = [
    {alias: "AD_GOOD", title: 'Хорошо', view: "success"},
    {alias: "AD_OFF", title: 'Отключено', view: "system"},
    {alias: "NEED_REPORT", title: 'Требуется отчет', view: "warning"},
    {alias: "FAIL_REPORT", title: 'Пропущен отчет', view: "error"},
    {alias: null, title: 'Пропущен отчет', view: "error"},
    {alias: "MONITOR", title: 'Мониторинг', view: "normal"},
]


let actions = [
    {
        id: 1,
        alias: 'CAMPAIGN_OFF',
        translation: 'Кампания отключена',
        view: "alert",
        status: "Отключен"
    },
    {
        id: 2,
        alias: 'CAMPAIGN_ON',
        translation: 'Кампания включена',
        view: "success",
        status: "Мониторинг"
    },
    {
        id: 3,
        alias: 'COMMENT_CREATE',
        translation: 'Написан комментарий',
        view: "brand",
        status: "Мониторинг"
    }
]
let causes = [
    {
        id: 1,
        alias: 'CAMPAIGN_OFF',
        translation: 'Кампания отключена',
        view: "alert",
        status: "Отключен"
    },
    {
        id: 2,
        alias: 'CAMPAIGN_ON',
        translation: 'Кампания включена',
        view: "success",
        status: "Мониторинг"
    }]
export const AdsCompany = () => {
    const params = useParams()
    let id = params?.id

    const history = useHistory()
    const {request, loading} = useHttp()

    const activeFlowId = useSelector(state => state.app.activeFlowId)

    const [sellerId, setSellerId] = React.useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const {flowInfo} = useSelector(state => state.app)
    const [activeItem, setActiveItem] = React.useState({});

    const [rows, setRows] = React.useState([])
    const [columns,] = React.useState([
        {
            title: 'Название',
            sortable: true,
            accessor: 'title',
            width: 300,
            renderCell: (row) => <Text onClick={() => {
                if (row.type === "ad") {
                    setIsSidebarOpen(true)
                    setActiveItem(row)
                }
            }} size="s">{row.title}</Text>
        },
        {
            title: 'Тип',
            sortable: true,
            accessor: 'type',
            sortByField: "type",
            renderCell: (row) => <Text size="s">{adTypes[row.type]}</Text>
        },
        {
            title: 'Длительность',
            sortable: true,
            accessor: 'duration',
            sortByField: 'duration',
            renderCell: (row) => <Text size="s">{row.duration + " дней"}</Text>
        },
        {
            title: 'Статус',
            sortable: true,
            accessor: 'status',
            sortByField: "active",
            renderCell: (row) => {
                let type = {
                    type: row.type,
                    groupId: row?.groupId,
                    campId: row?.campId,
                    adsId: row?.adsId,
                }
                return <Switch disabled={loading} onChange={() => toggleSwitch(row.id, type, row.active)}
                               checked={row.active}/>
            }
        },
        {
            title: 'Отчет',
            sortable: true,
            accessor: 'report',
            renderCell: (row) => row.reportStatus?.view && <Badge size="s" status={row.reportStatus?.view}
                                                                  label={row.reportStatus?.title}
                                                                  view="stroked"/>
        },
        {
            title: 'Сегодня',
            sortable: true,
            accessor: 'today',
            sortByField: "value.today",
            renderCell: (row) => <Badge className="table__badge__circle" size="s"
                                        style={{backgroundColor: getColor(row.value.today)}} status={row.today}
                                        form="round"
                                        view="filled"/>
        },
        {
            title: 'Вчера',
            sortable: true,
            accessor: 'yesterday',
            sortByField: "value.yesterday",
            renderCell: (row) => <Badge style={{backgroundColor: getColor(row.value.yesterday)}}
                                        className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'Позавчера',
            sortable: true,
            accessor: 'before yesterday',
            sortByField: "value.beforeYesterday",
            renderCell: (row) => <Badge style={{backgroundColor: getColor(row.value.beforeYesterday)}}
                                        className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: '3 дня назад',
            sortable: true,
            accessor: '3 days ago',
            sortByField: "value.threeDays",
            renderCell: (row) => <Badge style={{backgroundColor: getColor(row.value.threeDays)}}
                                        className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        },
        {
            title: 'За 7 дней',
            sortable: true,
            accessor: '7 day',
            sortByField: "value.week",
            renderCell: (row) => <Badge style={{backgroundColor: getColor(row.value.week)}}
                                        className="table__badge__circle" size="s" status={row.today} form="round"
                                        view="filled"/>
        }
    ])
    const [filters, setFilters] = React.useState([
        {
            id: 'search',
            name: 'Название: ',
            filterer: (value, filtererValue) => {
                return filtererValue.some(item => item.title === value)
            },
            field: 'title',
            component: {
                name: TableTextFilter,
                props: {
                    withSearch: true,
                    items: []
                },
            }
        },
    ])

    const getStatus = (status) => {
        return status ? (status === "ACTIVE" || status === true) : false
    }

    React.useEffect(() => {
        let flowId = id
        const getInfo = async () => {

            let options = {
                stat: {
                    periods: {
                        yesterday,
                        week,
                        threeDays,
                        today,
                        beforeYesterday
                    },
                    periods_group: "cols_unmixed", //cols_unmixed, rows, cols_mixed
                    groups: ["rekl_ads", "rekl_group", "rekl_camp"],
                    filters: {flow_id: [+flowId || flowInfo.flowId]},
                    fields: ["fc_indicator"]
                }
            }

            let response = await request("/v1/stats/my-template-data/stat_webmaster", "post", options)
            if (response && response?.length > 0) {
                let sellerId = response[0].seller_id
                setSellerId(sellerId)
                let rows = []
                let filterItems = []
                response.forEach((item, i) => {
                    if (!!item.camp_id) {
                        let value = {
                            yesterday: item?.fc_indicator__yesterday,
                            week: item?.fc_indicator__week,
                            threeDays: item?.fc_indicator__threeDays,
                            today: item?.fc_indicator__today,
                            beforeYesterday: item?.fc_indicator__beforeYesterday
                        }
                        let user = {
                            avatar: item?._user?.avatar,
                            email: item?._user?.email || item?._user?.username,
                            first_name: item?._user?.profile.first_name,
                            last_name: item?._user?.profile.last_name,
                        }
                        let duration = new Date().getUTCDay() - new Date(+(item._ad.created_at + "000")).getUTCDay()
                        if (duration < 1)
                            duration = 1
                        let newFilterItems = [
                            {
                                name: item.rekl_camp + "(Компания)",
                                value: item.rekl_camp + item.camp_id,
                                type: "company",
                                title: item.rekl_camp
                            },
                            {
                                name: item.rekl_group + "(Группа)",
                                value: item.rekl_group + item.adset_id,
                                type: "group",
                                title: item.rekl_group
                            },
                            {
                                name: item.rekl_ads + "(Объявление)",
                                value: item.rekl_ads + item.ads_id,
                                type: "ad",
                                title: item.rekl_ads
                            },
                        ]
                        if (!!filterItems.length)
                            newFilterItems?.forEach(item => {
                                let isSame = !!filterItems.find(filItem => item.name === filItem.name)
                                if (isSame)
                                    item.type === "ad" && filterItems.push({...item, name: item.name + ` (1)`})
                                else
                                    filterItems.push(item)

                            })
                        else
                            filterItems = [...newFilterItems]
                        let newItem = {
                            campId: item.camp_id || `comp${i}`,
                            id: item.camp_id || `comp${i}`,
                            title: item.rekl_camp || "-",
                            type: 'comp',
                            active: getStatus(item.camp_active),
                            duration,
                            user,
                            value,
                            rows: [
                                {
                                    title: item.rekl_group || "-",
                                    type: 'group',
                                    groupId: item.adset_id || `group${i}`,
                                    campId: item.camp_id || `comp${i}`,
                                    id: item.adset_id || `group${i}`,
                                    active: getStatus(item.adset_active),
                                    duration,
                                    value,
                                    user: user,
                                    rows: [
                                        {
                                            adsId: item.ads_id || `ad${i}`,
                                            campId: item.camp_id || `comp${i}`,
                                            compName: item.rekl_camp || "-",
                                            groupName: item.rekl_group || "-",
                                            groupId: item.adset_id || `group${i}`,
                                            id: item.ads_id || `ad${i}`,
                                            title: item.rekl_ads || "-",
                                            type: 'ad',
                                            value,
                                            user: user,
                                            duration,
                                            reportStatus: reportStatuses.find(status => status.alias === item?._ad?.ad_status) || {},
                                            active: item.ads_active === "" ? item?._ad?.status : getStatus(item.ads_active)
                                        },
                                    ]
                                }
                            ]
                        }

                        if (rows.length === 0) {
                            rows.push(newItem)
                        } else {
                            let index = -1
                            let isExist = rows?.find((item, i) => {
                                if (item.campId === newItem.campId) {
                                    index = i
                                    return true
                                }
                                return false
                            })
                            if (!!isExist) {
                                let group = newItem.rows[0]
                                let groupIndex = 0
                                let existGroup = rows[index].rows.find((row, i) => {
                                    if (group.groupId === row.groupId)
                                        groupIndex = i
                                    return group.groupId === row.groupId
                                })
                                if (!!existGroup) {
                                    let arr = [...existGroup.rows, ...group.rows]
                                    arr = arr.reduce((prev, t) => {
                                        if (typeof prev[t.adsId] === 'undefined') {
                                            prev[t.adsId] = [];
                                        }
                                        prev[t.adsId].push(t);
                                        return prev;
                                    }, {})
                                    let groupRows = []
                                    for (let i in arr) {
                                        if (arr[i]) {
                                            groupRows = [...groupRows, arr[i][0]]
                                        }
                                    }
                                    let beforeLength = rows[index].rows[groupIndex].rows.length
                                    let afterLength = groupRows.length

                                    rows[index].rows[groupIndex].rows = groupRows
                                    if (afterLength > beforeLength) {
                                        rows[index].duration = rows[index].duration + duration
                                        rows[index].rows[groupIndex].duration = rows[index].rows[groupIndex].duration + duration
                                    }
                                } else {
                                    rows[index].duration = rows[index].duration + duration
                                    rows[index].rows.push(group)
                                }
                            } else {
                                rows.push(newItem)
                            }
                        }
                    }
                })
                setRows(rows)
                setFilters(prevState => {
                    return prevState.map((item, i) => {
                        if (i === 0)
                            return {
                                ...item,
                                component: {...item.component, props: {...item.component.props, items: filterItems}}
                            }
                        return item
                    })
                })
            }
        }
        getInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (isSidebarOpen) {
            document.body.classList.add("overflow__hidden")
        } else {
            document.body.classList.remove("overflow__hidden")
        }
    })

    const toggleSwitch = async (id, idType, status) => {

        let types = {
            group: "fb-adsets",
            ad: "fb-ads",
            comp: "fb-campaigns",
        }

        let ids = ["comp", "ad", "group"]
        let isFakeId = ids.some(item => id.includes(item))
        let type = types[idType.type]
        let action = status ? "disable" : "enable"
        let needStatus = status ? "AD_OFF" : "MONITOR"
        let reportStatus = reportStatuses.find(status => status.alias === needStatus)

        try {
            let response = !isFakeId && await request(`/v1/${type}/${id}/${action}`, "post")
            if (response && response.success) {
                setRows(prevState => {
                    return prevState?.map(item => {
                        if (item.id === idType.campId) {
                            let active = !item.active
                            if (!!idType.groupId) {
                                return {
                                    ...item, rows: item.rows.map(group => {
                                        active = !group.active
                                        if (idType.groupId === group.id) {
                                            if (!!idType.adsId) {
                                                return {
                                                    ...group, rows: group.rows.map(ad => {
                                                        active = !ad.active
                                                        if (idType.adsId === ad.id)
                                                            return {...ad, active, reportStatus, isChanged: true}
                                                        return ad
                                                    })
                                                }
                                            }
                                            return {
                                                ...group, active, rows: group.rows.map(ad => {
                                                    return {...ad, active, reportStatus, isChanged: true}
                                                })
                                            }
                                        }
                                        return group
                                    })
                                }
                            }
                            return {
                                ...item, active, rows: item.rows.map(group => {
                                    return {
                                        ...group, active,
                                        rows: group.rows.map(ad => {
                                            return {...ad, active, reportStatus, isChanged: true}
                                        })
                                    }
                                })
                            }

                        }
                        return item
                    })
                })
                setActiveItem(prevState => {
                    if (prevState)
                        return {...prevState, active: !!prevState.active ? "" : "ACTIVE", isChanged: true, reportStatus}
                    return prevState
                })
            }
        } catch (e) {
        }
    }

    return (
        <div>
            <div className="flex">
                <Text size="3xl" weight="bold">Объявления</Text>
                <div
                    onClick={() => history.push((!!sellerId || !!activeFlowId) ? `/webmaster_flows/${activeFlowId || sellerId}` : "/")}>
                    <Button size="xs" label={<Text className="flex" size="xs">
                        <IconBackward size="xs"/>К офферам и потокам</Text>} view="ghost"/>
                </div>
            </div>
            <Table filters={filters} isExpandedRowsByDefault={true} rows={rows} columns={columns}/>
            {loading && <ModalLoader/>}
            <AdSidebar flowId={id} activeItem={activeItem} changeMainRows={setRows} isSidebarOpen={isSidebarOpen}
                       changeLoading={loading}
                       setIsSidebarOpen={setIsSidebarOpen} setActiveItem={setActiveItem} toggleSwitch={toggleSwitch}/>
        </div>
    );
}

const AdSidebar = ({
                       isSidebarOpen,
                       setIsSidebarOpen,
                       activeItem,
                       changeMainRows,
                       toggleSwitch,
                       flowId,
                       setActiveItem,
                       changeLoading
                   }) => {
    const [columns,] = React.useState([
        {
            title: 'Наименование',
            accessor: 'title',
            width: 250
        },

        {
            title: 'Сегодня',
            accessor: 'today',
            renderCell: (row) => row.type === "indicator" ? <Badge className="table__badge__circle"
                                                                   style={{backgroundColor: getColor(row.today)}}
                                                                   size="s" status={row.today} form="round"
                                                                   view="filled"/> :
                <Text size="s">{row.today || "-"}</Text>
        },
        {
            title: 'Вчера',
            accessor: 'yesterday',
            renderCell: (row) => row.type === "indicator" ? <Badge className="table__badge__circle"
                                                                   style={{backgroundColor: getColor(row.yesterday)}}
                                                                   size="s" status={row.today} form="round"
                                                                   view="filled"/> :
                <Text size="s">{row.yesterday || "-"}</Text>
        },
        {
            title: 'Позавчера',
            accessor: 'before yesterday',
            renderCell: (row) => row.type === "indicator" ? <Badge className="table__badge__circle"
                                                                   style={{backgroundColor: getColor(row.beforeYesterday)}}
                                                                   size="s" status={row.today} form="round"
                                                                   view="filled"/> :
                <Text size="s">{row.beforeYesterday || "-"}</Text>
        },
        {
            title: '3 дня назад',
            accessor: '3 days ago',
            renderCell: (row) => row.type === "indicator" ? <Badge className="table__badge__circle"
                                                                   style={{backgroundColor: getColor(row.threeDays)}}
                                                                   size="s" status={row.today} form="round"
                                                                   view="filled"/> :
                <Text size="s">{row.threeDays || "-"}</Text>
        },
        {
            title: 'За 7 дней',
            accessor: '7 day',
            renderCell: (row) => row.type === "indicator" ? <Badge className="table__badge__circle"
                                                                   style={{backgroundColor: getColor(row.week)}}
                                                                   size="s" status={row.today} form="round"
                                                                   view="filled"/> :
                <Text size="s">{row.week || "-"}</Text>
        }
    ])
    const [rows, setRows] = React.useState([])
    const [isReportSidebarOpen, setIsReportSidebarOpen] = React.useState(false)

    const [data, setData] = React.useState(null)
    const [reports, setReports] = React.useState(null)
    const {request, loading} = useHttp()

    const getInfo = async () => {
        let options = {
            stat: {
                "groups": ["offer", "flow_domain", "flow_name", "rekl_ads", "rekl_group", "rekl_camp"],
                "filters": {flow_id: [+flowId], ad_id: [activeItem.id]},
                periods: {
                    yesterday,
                    week,
                    threeDays,
                    today,
                    beforeYesterday
                },
                fields: ["fc_indicator",
                    "lids",
                    "approve",
                    "perc_approve",
                    "pay_lid_usd",
                    "pay_approve_usd",
                    "pay_reklam_usd",
                    "fc_max_approve_cab",
                    "fc_max_approve"
                ]
            }
        }
        try {
            let res = await request("/v1/stats/my-template-data/stat_webmaster", "post", options)
            if (res && res?.length > 0) {
                let item = res[0]
                let rows = [
                    {
                        id: 1,
                        title: "Индикатор",
                        type: "indicator",
                        yesterday: item?.fc_indicator__yesterday,
                        week: item?.fc_indicator__week,
                        threeDays: item?.fc_indicator__threeDays,
                        today: item?.fc_indicator__today,
                        beforeYesterday: item?.fc_indicator__beforeYesterday
                    },
                    {
                        id: 2,
                        title: "Лиды",
                        type: "digit",
                        yesterday: item?.lids__yesterday,
                        week: item?.lids__week,
                        threeDays: item?.lids__threeDays,
                        today: item?.lids__today,
                        beforeYesterday: item?.lids__beforeYesterday
                    },
                    {
                        id: 3,
                        title: "Апрув",
                        type: "digit",
                        yesterday: item?.approve__yesterday,
                        week: item?.approve__week,
                        threeDays: item?.approve__threeDays,
                        today: item?.approve__today,
                        beforeYesterday: item?.approve__beforeYesterday
                    },
                    {
                        id: 4,
                        title: "% апрува",
                        type: "digit",
                        yesterday: item?.perc_approve__yesterday,
                        week: item?.perc_approve__week,
                        threeDays: item?.perc_approve__threeDays,
                        today: item?.perc_approve__today,
                        beforeYesterday: item?.perc_approve__beforeYesterday
                    },
                    {
                        id: 5,
                        title: "Стоимость лида",
                        type: "digit",
                        yesterday: item?.pay_lid_usd__yesterday,
                        week: item?.pay_lid_usd__week,
                        threeDays: item?.pay_lid_usd__threeDays,
                        today: item?.pay_lid_usd__today,
                        beforeYesterday: item?.pay_lid_usd__beforeYesterday
                    },
                    {
                        id: 6,
                        title: "Стоимость апрува",
                        type: "digit",
                        yesterday: item?.pay_approve_usd__yesterday,
                        week: item?.pay_approve_usd__week,
                        threeDays: item?.pay_approve_usd__threeDays,
                        today: item?.pay_approve_usd__today,
                        beforeYesterday: item?.pay_approve_usd__beforeYesterday
                    },
                    {
                        id: 7,
                        title: "Траты на рекламу",
                        type: "digit",
                        yesterday: item?.pay_reklam_usd__yesterday,
                        week: item?.pay_reklam_usd__week,
                        threeDays: item?.pay_reklam_usd__threeDays,
                        today: item?.pay_reklam_usd__today,
                        beforeYesterday: item?.pay_reklam_usd__beforeYesterday
                    },
                    {
                        id: 8,
                        title: "Предельная цена лида",
                        type: "digit",
                        yesterday: item?.fc_max_approve_cab__yesterday,
                        week: item?.fc_max_approve_cab__week,
                        threeDays: item?.fc_max_approve_cab__threeDays,
                        today: item?.fc_max_approve_cab__today,
                        beforeYesterday: item?.fc_max_approve_cab__beforeYesterday
                    },
                    {
                        id: 9,
                        title: "Предельная цена апрува",
                        type: "digit",
                        yesterday: item?.fc_max_approve__yesterday,
                        week: item?.fc_max_approve__week,
                        threeDays: item?.fc_max_approve__threeDays,
                        today: item?.fc_max_approve__today,
                        beforeYesterday: item?.fc_max_approve__beforeYesterday
                    }
                ]
                setRows(rows)
                setData(item)
            }
        } catch (e) {
        }
    }
    const getReports = async () => {
        try {
            if (!activeItem.id.includes("ad")) {
                let res = await request(`/v1/reports/ad_id/${activeItem.id}`, "get")
                if (res && res?.length > 0) {
                    setReports(res)
                }
            }
        } catch (e) {
        }
    }
    React.useEffect(() => {
        if (activeItem && activeItem?.id && !activeItem?.isChanged) {
            getInfo()
            getReports()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItem])

    const closeSidebar = () => {
        if (!isReportSidebarOpen) {
            setActiveItem(null)
            setIsSidebarOpen(false)
        }
    }
    return <>
        <Sidebar
            isOpen={isSidebarOpen}
            size="2/3"
            onClickOutside={closeSidebar}
            onEsc={closeSidebar}
        >
            <Sidebar.Content className="sidebar__content">
                <div className="flex">
                    <Text size="3xl" weight="bold">Объявление</Text>
                    <Button size="xs" label={<Text className="flex" size="xs"
                                                   onClick={() => setIsSidebarOpen(false)}>
                        <IconBackward size="xs"/> Закрыть</Text>} view="ghost"/>
                </div>
                <div className="sidebar__card__wrapper flex-block">
                    <Card className="sidebar__card half-width" verticalSpace="s" horizontalSpace="2xl" form="square">
                        {changeLoading && <ModalLoader/>}
                        <Text weight="bold" size="l">Информация об объявлении</Text>
                        <div className="sidebar__card__content">
                            <div className="sidebar__content__item">
                                <div className="table__cell__offer">
                                    <Avatar size="s"
                                            url={activeItem?.user?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                    <div style={{marginLeft: "15px"}}>
                                        <Text size="s" view="primary"
                                              className="table__text">{(activeItem?.user?.first_name || "") + " " + (activeItem?.user?.last_name || "")}</Text>
                                        <Text size="2xs" view="secondary"
                                              className="table__text">{activeItem?.user?.email}</Text>
                                        <Text size="2xs" view="secondary"
                                              className="table__text">{data?.seller}</Text>
                                    </div>
                                </div>
                                {activeItem?.reportStatus?.view &&
                                <Badge size="s" status={activeItem?.reportStatus?.view}
                                       label={activeItem?.reportStatus?.title} view="stroked"/>}
                            </div>
                            <div className="sidebar__content__item">
                                <div className="table__cell__offer">
                                    <Avatar size="s"
                                            url={facebook} className="ads__company__avatar"/>
                                    <div style={{marginLeft: "15px"}}>
                                        <Text size="s" view="primary"
                                              className="table__text">{activeItem?.title}</Text>
                                        <Text size="2xs" view="secondary" style={{width: "95%"}}
                                              className="table__text">{activeItem?.compName + " / " + activeItem?.groupName}</Text>
                                    </div>
                                </div>
                                <Switch disabled={changeLoading || loading}
                                        onChange={() => toggleSwitch(activeItem.id, {
                                            type: activeItem.type,
                                            groupId: activeItem?.groupId,
                                            campId: activeItem?.campId,
                                            adsId: activeItem?.adsId,
                                        }, activeItem.active)}
                                        checked={!!activeItem?.active}/>
                            </div>
                            <div className="sidebar__content__item">
                                <div className="table__cell__offer">
                                    <Avatar size="s"
                                            url={activeItem?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                    <div style={{marginLeft: "15px"}}>
                                        <Text size="s" view="primary"
                                              className="table__text">{data?.offer || "-"}</Text>
                                        <Text size="2xs" view="secondary"
                                              className="table__text">{data?.flow_id} - {data?.flow_name}
                                            <br/>{data?.flow_domain || "-"}</Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="sidebar__card half-width" verticalSpace="s" horizontalSpace="2xl" form="square">
                        <Text weight="bold" size="l">Отчеты и заметки</Text>
                        <div className="sidebar__card__content__right">
                            <div style={{width: "65%"}}>
                                <Text view="secondary" size="xs">Статус отчета:
                                    <Text className="inline__text" size="xs"
                                          view={activeItem?.status}>{(!!reports?.length && actions.find(item => item.id === +reports[reports.length - 1].action_id)?.translation) || "-"} </Text>
                                </Text>
                                <Text view="secondary" size="xs">Последняя дата отчета:
                                    <Text className="inline__text" size="xs"
                                          view={activeItem?.status}>{(!!reports?.length && getValidDate(reports[reports?.length - 1]?.moment)) || "-"} </Text>
                                </Text>
                                <Text view="secondary" size="xs">Кол-во отчетов:
                                    <Text className="inline__text" size="xs"
                                          view={activeItem?.status}>{reports?.length || "-"} </Text>
                                </Text>
                            </div>
                            <div className="table__cell__offer" style={{marginLeft: "25px"}}>
                                <Button onClick={() => setIsReportSidebarOpen(true)} label="Смотреть" size="xs"
                                        view="ghost"/>
                                <Button onClick={() => setIsReportSidebarOpen(true)} style={{marginLeft: "10px"}}
                                        label="Написать" size="xs" view="secondary"/>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="sidebar__content__statistic">
                    <Text weight="bold" size="l">Статистика</Text>
                    <Table rows={rows} columns={columns}/>
                </div>
            </Sidebar.Content>
        </Sidebar>
        <ReportSidebar reports={reports} ad={activeItem} setActiveItem={setActiveItem} changeMainRows={changeMainRows}
                       setReports={setReports}
                       isReportSidebarOpen={isReportSidebarOpen}
                       setIsReportSidebarOpen={setIsReportSidebarOpen}/>
    </>
}

const ReportSidebar = ({
                           isReportSidebarOpen,
                           setIsReportSidebarOpen,
                           changeMainRows,
                           setActiveItem,
                           reports,
                           setReports,
                           ad
                       }) => {

    const {handleSubmit, register, reset} = useForm()
    const {request, loading} = useHttp()
    const [items, setItems] = React.useState(reports)

    const profile = useSelector(state => state.profile.profile)

    React.useEffect(() => {
        if (reports)
            setItems(reports)
    }, [reports])

    const sendReport = async (data) => {
        let cause_alias = !!ad.active ? "CAMPAIGN_ON" : "CAMPAIGN_OFF"
        let action_alias = ad?.isChanged ? !!ad.active ? "CAMPAIGN_ON" : "CAMPAIGN_OFF" : "COMMENT_CREATE"
        let action_id = +actions.find(act => act.alias === action_alias).id
        let cause_id = +causes.find(act => act.alias === cause_alias).id

        let Reports = {
            comment: data.report,
            ad_id: ad.id,
            cause_alias,
            action_alias
        }
        try {
            let res = !!data.report && await request(`/v1/reports`, "post", {Reports})
            if (res && res?.comment) {
                if ((ad?.reportStatus?.alias === "NEED_REPORT" || ad?.reportStatus?.alias === null) && ad?.active) {
                    let monitoringStatus = reportStatuses.find(item => item.alias === "MONITOR")
                    changeMainRows(prevState => {
                        if (prevState) {
                            return prevState?.map(item => {
                                if (item?.rows) {
                                    return {
                                        ...item, rows: item.rows?.map(row => {
                                            if (row?.rows) {
                                                return {
                                                    ...row, rows: row.rows.map(adItem => {
                                                        if (adItem.id === ad.id) {
                                                            return {...adItem, reportStatus: monitoringStatus}
                                                        }
                                                        return adItem
                                                    })
                                                }
                                            }
                                            return row
                                        })
                                    }
                                }
                                return item
                            })
                        }
                        return prevState
                    })
                    setActiveItem(prevState => ({...prevState, reportStatus: monitoringStatus}))
                }
                reset({"report": ""})
                let newReport = {
                    comment: res.comment,
                    action_id,
                    cause_id,
                    moment: new Date().toISOString(),
                    _user: {
                        profile: {
                            first_name: profile?.first_name,
                            last_name: profile?.last_name,
                        },
                        avatar: profile?.avatar,
                        email: profile?.email
                    }
                }
                setReports(prevState => {
                    if (!prevState)
                        return [newReport]
                    return [...prevState, newReport]
                })
            }
        } catch (e) {
        }
    }

    const getStatus = (id) => {
        let action = actions.find(item => +item.id === +id)
        return (action?.alias === "CAMPAIGN_ON" || action?.alias === 'COMMENT_CREATE') ? "Мониторинг" : "Отключен"
    }

    return <Sidebar
        isOpen={isReportSidebarOpen}
        size="m"
        onClickOutside={() => setIsReportSidebarOpen(false)}
        onEsc={() => setIsReportSidebarOpen(false)}
    >
        <Sidebar.Content className="sidebar__content">
            <div className="reports-sidebar__content">
                <div className="sidebar__report">
                    <Text weight="bold" size="l">Отчеты</Text>
                    <div className="sidebar__cards__wrapper">
                        {
                            items
                                ? items.map((item, i) => {
                                    let view = actions.find(act => act.id === +item.action_id)?.view
                                    let activeAction = actions.find(act => act.id === +item.action_id)
                                    return (view === "alert" || item?.is_system === "1")
                                        ?
                                        <Card key={i} className="report__card type__alert" verticalSpace="m" status="alert"
                                              view="alert"
                                              horizontalSpace="2xl" form="square">
                                            {item?.is_system === "1" ? <>
                                                    <Text size="s">Системное уведомление</Text>
                                                    <Text size="2xs" view="ghost">Автаматическое уведомление</Text>
                                                </>
                                                : <div className="table__cell__offer">
                                                    <Avatar size="s"
                                                            url={item?._user?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                                    <div style={{marginLeft: "11px"}}>
                                                        <Text
                                                            size="s">{item?._user?.profile?.first_name || ""} {" " + (item?._user?.profile?.first_name || "")}</Text>
                                                        <Text size="2xs"
                                                              view="ghost">{item?._user?.email || item?._user?.username}</Text>
                                                    </div>
                                                </div>}
                                            <Text className="report__margin" weight="bold" size="xs">Действие</Text>
                                            <Text size="xs"
                                                  view={view}>{activeAction?.translation}</Text>
                                            <Text size="xs" view="secondary">Статус объявления был изменен на
                                                - {getStatus(item?.action_id)}</Text>

                                            <Text weight="bold" size="2xs" style={{textAlign: "right"}}
                                                  view="secondary">{!!item.moment && getValidDate(item.moment)}</Text>
                                        </Card>
                                        : <Card key={i} className="report__card type__success" verticalSpace="m"
                                                horizontalSpace="2xl"
                                                form="square">
                                            <Text className="report-read-status" size="xs"
                                                  view={view}>{item?.is_read === "1" && "Просмотрено"}</Text>
                                            <Text className="report-read-status" size="xs"
                                                  view={item?.is_read === "1" ? "success" : "secondary"}>{item?.is_read === "1" && "Просмотрено"}</Text>

                                            <div className="table__cell__offer">
                                                <Avatar size="s"
                                                        url={item?._user?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                                                <div style={{marginLeft: "11px"}}>
                                                    <Text
                                                        size="s">{item?._user?.profile?.first_name || ""} {" " + (item?._user?.profile?.first_name || "")}</Text>
                                                    <Text size="2xs"
                                                          view="ghost">{item?._user?.email || item?._user?.username}</Text>
                                                </div>
                                            </div>
                                            <Text className="report__margin" weight="bold" size="xs">Действие</Text>
                                            <Text size="xs"
                                                  view={view}>{activeAction?.translation}</Text>
                                            <Text className="report__margin" weight="bold" size="xs">Комментарий</Text>
                                            <Text size="xs" view="secondary">{item.comment}</Text>
                                            <Text size="xs" view="secondary">Статус объявления был изменен на
                                                - {getStatus(item?.action_id)}</Text>

                                            <Text weight="bold" size="2xs" style={{textAlign: "right"}}
                                                  view="secondary">{!!item.moment && getValidDate(item.moment)}</Text>
                                        </Card>
                                })
                                : <Text weight="regular" size="l">Нет данных</Text>
                        }
                    </div>

                </div>
                <div style={{marginTop: "11px"}}>
                    <Text weight="bold" size="l" style={{marginBottom: "10px"}}>Написать отчеты</Text>
                    <div className="table__cell__offer link">
                        <Avatar size="s" url={profile?.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                        <div style={{marginLeft: "15px"}}>
                            <Text size="s" view="primary"
                                  className="table__text">{profile?.first_name + " " + (profile?.last_name || "")}</Text>
                            <Text size="2xs" view="secondary" className="table__text">{profile?.email}</Text>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(sendReport)}>
                        <textarea {...register("report")} className="report__textarea"
                                  id="report" cols="34" rows="2"/>
                        <div className="flex" style={{justifyContent: "flex-end"}}>
                            <Button disabled={loading} style={{marginLeft: "10px"}} label="Отправить" size="xs"
                                    view="secondary"/>
                        </div>
                    </form>
                </div>
            </div>
        </Sidebar.Content>
    </Sidebar>
}

const getValidDate = (date) => {
    if (!date)
        return ""
    let allDate = new Date(date).toLocaleString()
    let split = allDate?.split(",")
    if (!!split.length) {
        let firstPeace = split[0]
        let secondPeace = split[1]?.trim().split(":")
        return firstPeace + (secondPeace ? " в " + secondPeace[0] + ":" + secondPeace[1] : "")
    }
    return ""
}