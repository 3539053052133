import React from "react";
import {Error404} from "./pages/system/Error404";
import {Main} from "./pages/Main";
import {Login} from "./pages/Login";
import {Template} from "./pages/Template";
import {WebmasterFlows} from "./components/WebmasterFlows";
import {AdsCompany} from "./components/AdsCompany";

export const routes = [
  {
    path: "/login",
    meta: {
      layout: "empty",
      auth: false
    },
    exact: true,
    render: <Login />
  },
  {
    path: "/template/:alias/:id?",
    meta: {
      layout: "main",
      auth: true
    },
    exact: true,
    render: <Template />
  },
  {
    path: "/webmaster_flows/:id",
    meta: {
      layout: "main",
      auth: true
    },
    exact: true,
    render: <WebmasterFlows />
  },
  {
    path: "/ads-company/:id",
    meta: {
      layout: "main",
      auth: true
    },
    exact: true,
    render: <AdsCompany />
  },
  {
    path: "/main",
    meta: {
      layout: "main",
      auth: true
    },
    exact: true,
    render: <Main />
  },
  {
    path: "/",
    meta: {
      layout: "main",
      auth: true
    },
    exact: true,
    render: <Main />
  },
  {
    path: "*",
    meta: {
      layout: "main",
      auth: false
    },
    exact: false,
    render: <Error404 />
  },
];
