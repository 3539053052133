const initialState = {
    profile: null
}

const profileReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'PROFILE/SET_PROFILE': {
            return {
                ...state,
                profile: action.payload
            }
        }
        default:
            return state
    }
}

export const actionsProfile = {
    setProfile: (payload) => ({
        type: 'PROFILE/SET_PROFILE',
        payload
    })
}


export default profileReducer