import {instance} from "./api";

export const ProfileAPI = {

    getUserInfo(auth) {
        return instance.get("/v1/users/me", {
            headers: {
                "Authorization": "Basic " + (auth || localStorage.getItem("access_token"))
            },
            params: {
                expand: "profile"
            }
        }).then((response) => {
            return response.data;
        });
    },
    getTemplates(auth) {
        return instance.get("/v1/stats/my-templates", {
            headers: {
                "Authorization": "Basic " + auth
            }
        }).then((response) => {
            return response.data;
        });
    },
};