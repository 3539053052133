import React from 'react';
import {Text} from "@consta/uikit/Text";
import {Table, TableTextFilter} from "@consta/uikit/Table";
import {Avatar} from "@consta/uikit/Avatar";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {actionsApp} from "../redux/app-reducer";
import {ModalLoader} from "../utils/elements/LoaderModal";

export const Users = ({users, loading}) => {
    const templates = useSelector(state => state.app.templates)

    const history = useHistory()
    const dispatch = useDispatch()

    const [teamleadTemplate, setTeamleadTemplate] = React.useState({})

    React.useEffect(() => {
        if (templates) {
            let teamlead = templates.find(item => item.alias === "teamlead")
            setTeamleadTemplate(teamlead.schema)
        }
    }, [templates])

    React.useEffect(() => {
        if (teamleadTemplate && users) {
            let buttons = []
            const getTitle = (user) => (user?._user?.profile?.first_name || "") + " " + (user?._user?.profile?.last_name || "") + (user?.seller || "")
            let filterItems = users?.map(item => {
                let title = getTitle(item)
                return {name: title, value: title}
            })
            teamleadTemplate?.groups_buttons && Object.keys(teamleadTemplate?.groups_buttons).forEach(item => {
                return teamleadTemplate?.groups_buttons[item].forEach((button) => {
                    return buttons.push(button)
                })
            })
            let usersEdited = [...users].map(item => ({...item, buttons ,title: getTitle(item)}))
            setRows(usersEdited)
            setFilters(prevState => {
                return prevState.map((item, i) => {
                    if (i === 0)
                        return {
                            ...item,
                            component: {...item.component, props: {...item.component.props, items: filterItems}}
                        }
                    return item
                })
            })
        }
    }, [users, teamleadTemplate])

    const getFlow = (id) => {
        if (id) {
            dispatch(actionsApp.setWebmasterFlowInfo({userId: id}))
            history.push(`/webmaster_flows/${id}`)
        }
    }

    const [columns,] = React.useState([
            {
                title: 'Пользователь',
                renderCell: (user) =>
                    <div onClick={() => getFlow(user.seller_id)} className="table__cell__offer link">
                        <Avatar size="s" url={user.avatar || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                        <div style={{marginLeft: "15px"}}>
                            <Text size="s" view="primary"
                                  className="table__text">{user?._user?.profile.first_name + " " + (user?._user?.profile?.last_name || "")}</Text>
                            <Text size="2xs" view="secondary" className="table__text">{user?.seller}</Text>
                        </div>
                    </div>,
                filterer: TableTextFilter,
                sortable: true,
                accessor: 'title',
                width: 250
            },
            {
                title: 'Пропущенный отчет',
                renderCell: (user) => <Text weight="bold" size="s" view="linkMinor"
                                            className="table__text">{user?.rep_ad_fail_rep}</Text>,
                sortable: true,
                accessor: 'data.missedReports',
                sortByField: "rep_ad_fail_rep",
            },
            {
                title: 'Мониторинг',
                renderCell: (user) => <Text weight="bold" size="s" view="linkMinor"
                                            className="table__text">{user?.rep_ad_monitor || 0}</Text>,
                sortable: true,
                accessor: 'rep_ad_monitor',
                sortByField: "rep_ad_monitor",
            },
            {
                title: 'Проблема',
                renderCell: (user) => <Text weight="bold" size="s" view="alert"
                                            className="table__text">{user?.rep_ad_report || 0}</Text>,
                sortable: true,
                accessor: 'rep_ad_report',
                sortByField: "rep_ad_report",
            },
            {
                title: 'Хорошо',
                renderCell: (user) => <Text weight="bold" size="s" view="success"
                                            className="table__text">{user?.rep_ad_good || 0}</Text>,
                sortable: true,
                accessor: 'rep_ad_good',
                sortByField: "rep_ad_good",
            },
            {
                title: 'Пропущенный отчет',
                renderCell: (user) => <Text weight="bold" size="s" view="success"
                                            className="table__text">{user?.rep_ad_fail_rep || 0}</Text>,
                sortable: true,
                accessor: 'rep_ad_fail_rep',
                sortByField: "rep_ad_fail_rep",
            },
            {
                title: 'Выключены',
                renderCell: (user) => <Text weight="bold" size="s" view="success"
                                            className="table__text">{user?.rep_ad_off || 0}</Text>,
                sortable: true,
                accessor: 'rep_ad_off',
                sortByField: "rep_ad_off",
            },
        ])
    const [filters, setFilters] = React.useState([
            {
                id: 'id',
                name: 'Месторождение: ',
                field: 'title',
                filterer: (cellValue, filterValues) => {
                    return filterValues.some((filterValue) => filterValue && filterValue.value === cellValue);
                },
                component: {
                    name: TableTextFilter,
                    props: {
                        withSearch: true,
                        items: [],
                    },
                },
            }
        ])
    const [rows, setRows] = React.useState([])

    return (
        <div>
            <Text size="3xl" weight="bold"> Пользователи</Text>
            {loading && <ModalLoader/>}
            {teamleadTemplate && <Table filters={filters} columns={columns} rows={rows}
                                        emptyRowsPlaceholder={<Text>Здесь пока нет данных</Text>}/>}
        </div>
    );
}
