import React from 'react';
import {useSelector} from "react-redux";
import {Button} from "@consta/uikit/Button";
import {Link, Redirect} from "react-router-dom";
import "./Main.css"

export const Main = () => {

    const templates = useSelector(state => state.app.templates)
    const profile = useSelector(state => state.profile.profile)
    const isAuth = useSelector(state => state.auth.isAuth)

    if (!profile?.isTeamlead && isAuth && profile) {
        return <Redirect to={`/webmaster_flows/${profile.id}`}/>
    }

    return <div>
        <div className="template__wrapper">
            {
                templates?.map((temp) => {
                    return <Link to={`/template/${temp.alias}`} key={temp.id} className="template__btn__link">
                        <Button label={temp.name}>{temp.name}</Button>
                    </Link>

                })
            }
        </div>
    </div>
}
