import React from "react";
import {Header as HeaderComponent, HeaderButton, HeaderLogin, HeaderMenu, HeaderModule} from "@consta/uikit/Header";
import {NavLink} from "react-router-dom";
import {Text} from "@consta/uikit/Text";
import {IconRing} from "@consta/uikit/IconRing";
import {IconBento} from "@consta/uikit/IconBento";
import {useDispatch, useSelector} from "react-redux";
import {useOutsideAlerter} from "../utils/hooks/outsideClick";
import {logout} from "../redux/auth-reducer";

export const Header = () => {
    const isAuth = useSelector(state => state.auth.isAuth)
    const {profile} = useSelector(state => state.profile)

    const [isShowInfo, setIsShowInfo] = React.useState(false)
    const logoutRef = React.useRef(null)

    const dispatch = useDispatch()
    useOutsideAlerter(logoutRef, setIsShowInfo)
    const [menuItems,] = React.useState([
        {
            label: "Аналитика",
            href: "/",
            active: true
        }])

    const logoutAcc = () => {
        dispatch(logout())
    }

    return <HeaderComponent
        leftSide={
            <>
                <HeaderModule>
                    <NavLink className="logo__link" to="/">
                        <Text weight="black">7leads</Text>
                    </NavLink>
                </HeaderModule>
                <HeaderModule>
                    <HeaderMenu items={menuItems}/>
                </HeaderModule>
            </>
        }
        rightSide={
            <>

                <HeaderModule indent="s">
                    <HeaderButton iconLeft={IconRing}/>
                </HeaderModule>
                <HeaderModule indent="s">
                    <HeaderButton iconLeft={IconBento}/>
                </HeaderModule>
                <HeaderModule indent="s">
                    <HeaderLogin onClick={() => setIsShowInfo(prevState => !prevState)}
                                 personAvatarUrl={profile?.avatar || ""}
                                 personName={profile?.first_name + " " + profile?.last_name}
                                 personInfo={profile?.email || ""} isLogged={isAuth}/>
                    {isShowInfo && <div ref={logoutRef} className="user__info">
                        <span onClick={logoutAcc} className="logout__btn">Выйти</span>
                    </div>}
                </HeaderModule>
            </>
        }
    />
};
