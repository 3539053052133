import React from "react";

//hook to hide popup when click outside of his body
export const useOutsideAlerter = (ref, setState, exception) => {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (exception && exception.current && exception.current.contains(event.target))
          return;
        setState(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [exception, ref, setState]);
};