import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleWare from "redux-thunk";
import appReducer from "./app-reducer";
import authReducer from "./auth-reducer";
import profileReducer from "./profile-reducer";

let reducers = combineReducers({
  app: appReducer,
  profile: profileReducer,
  auth: authReducer,
})

/**
 * add redux thunk middleWare
 * and create store
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleWare)));

export default store