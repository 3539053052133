import React from 'react';
import {Text} from "@consta/uikit/Text";
import {Table, TableTextFilter} from "@consta/uikit/Table";
import {Link, useHistory, useParams} from "react-router-dom";
import {Button} from "@consta/uikit/Button";
import {IconBackward} from "@consta/uikit/IconBackward";
import {Avatar} from "@consta/uikit/Avatar";
import {useHttp} from "../utils/hooks/http.hook";
import {useDispatch, useSelector} from "react-redux";
import {actionsApp} from "../redux/app-reducer";
import {ModalLoader} from "../utils/elements/LoaderModal";

let dateTime = 86400000
let date = new Date().getTime()
let week = new Date(date - 7 * dateTime).toLocaleDateString() + " - " + new Date().toLocaleDateString()
let today = new Date().toLocaleDateString() + " - " + new Date().toLocaleDateString()

export const WebmasterFlows = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams()

    let id = params?.id
    const {request, loading} = useHttp()

    const {profile} = useSelector(state => state.profile)
    const {webmasterFlowInfo} = useSelector(state => state.app)

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        let sellerId = id || profile.id
        let options = {
            stat: {"groups": ["offer", "flow_domain", "flow_name"],
                periods: {week, today},
                "filters": {seller_id: [+sellerId]},
                fields: ["rep_ad_monitor", "rep_ad_report", "rep_ad_good", "rep_ad_fail_rep", "rep_ad_off"]
            }
        }
        const getInfo = async () => {
            let response = await request("/v1/stats/my-template-data/stat_webmaster", "post", options)
            if (response) {
                setData(response?.map((item, i) => ({...item, id: i})))
            }
        }
        if (profile)
            getInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webmasterFlowInfo, id])

    const getFlow = (id) => {
        if (id) {
            dispatch(actionsApp.setFlowInfo({flowId: id}))
            history.push(`/ads-company/${id}`)
        }
    }
    const [columns,] = React.useState([
        {
            title: 'Поток',
            renderCell: (item) => <div onClick={() => getFlow(item.flow_id)} className="link">
                <Text size="s" view="primary"
                      className="table__text">{item.flow_id} -{item.flow_name}
                    <br/>{item.flow_domain}</Text>
            </div>,
            sortable: true,
            accessor: 'title',
            width: 400
        },
        {
            title: 'Данные',
            accessor: 'flow_ind_lead_campaigns',
            sortable: true,
            width: 202,
            renderCell: (row) => {
                let isSuccess = Object.keys(row).find(item => item === "rep_ad_good__week")
                return <Text size="s" view={isSuccess ? "success" : "alert"}
                             className="table__text">{isSuccess ? "Ok" : "Не загрузились"}</Text>
            }
        },
        {
            title: 'Оффер',
            accessor: 'offer',
            sortable: true,
            width: 202,
            renderCell: (row) => <div className="table__cell__offer"><Avatar size="s"
                                                                             url={row?._offer || "https://i.ibb.co/K2R8Lqb/Rectangle-1496.png"}/>
                <Text style={{width: "80%", marginLeft: "6px"}} size="2xs" view="ghost"
                      className="table__text">{row.offer}</Text></div>
        },
        {
            title: 'Мониторинг',
            renderCell: (item) => <Text weight="bold" size="s" view="linkMinor">{+item.rep_ad_monitor__week}</Text>,
            sortable: true,
            accessor: 'rep_ad_monitor__week',
        },
        {
            title: 'Проблема',
            renderCell: (item) => <Text weight="bold" size="s" view="alert">{+item.rep_ad_report__week}</Text>,
            sortable: true,
            accessor: 'rep_ad_report__week',
        },
        {
            title: 'Хорошо',
            renderCell: (item) => <Text weight="bold" size="s" view="success">{+item?.rep_ad_good__week}</Text>,
            sortable: true,
            accessor: 'rep_ad_good__week',
        },
        {
            title: 'Пропущенный отчет',
            renderCell: (item) => <Text weight="bold" size="s" view="success">{+item?.rep_ad_fail_rep__week}</Text>,
            sortable: true,
            accessor: 'rep_ad_fail_rep__week',
        },
        {
            title: 'Выключены',
            renderCell: (item) => <Text weight="bold" size="s" view="success">{+item?.rep_ad_off__week}</Text>,
            sortable: true,
            accessor: 'rep_ad_off__week',
        },
    ])

    const [filters, setFilters] = React.useState([
        {
            id: 'id',
            name: 'Название: ',
            field: 'title',
            filterer: (cellValue, filterValues) => {
                return filterValues.some((filterValue) => filterValue && filterValue.name === cellValue);
            },
            component: {
                name: TableTextFilter,
                props: {
                    withSearch: true,
                    items: [],
                },
            },
        }
    ])
    const [rows, setRows] = React.useState(data)

    React.useEffect(() => {
        !!id && dispatch(actionsApp.setActiveFlowId(+id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (data) {
            const getTitle = (item) => item.flow_id + "-" + item.flow_name + " " + item.flow_domain
            let filterItems = data?.map(item => {
                let title = getTitle(item)
                return {name: title, value: title}
            })
            setFilters(prevState => {
                return prevState.map((item, i) => {
                    if (i === 0)
                        return {
                            ...item,
                            component: {...item.component, props: {...item.component.props, items: filterItems}}
                        }
                    return item
                })
            })
            setRows(data?.map(item => ({...item, title: getTitle(item)})))
        }
    }, [data])

    return (
        <div>
            <div className="flex">
                <Text size="3xl" weight="bold">Потоки и офферы</Text>
                {profile.isTeamlead && <Link to="/template/stat_webmaster">
                    <Button size="xs" label={<Text className="flex" size="xs">
                        <IconBackward size="xs"/> К списку пользователей</Text>} view="ghost"/>
                </Link>}
            </div>
            {loading && <ModalLoader />}
            <Table size="s" filters={filters} columns={columns || []} rows={rows || []}
                                          emptyRowsPlaceholder={<Text>Здесь пока нет данных</Text>}/>
        </div>
    );
}