import React from 'react';
import {useParams} from "react-router-dom"
import {WebmasterFlows} from "../components/WebmasterFlows";
import {Users} from "../components/Users";
import {useHttp} from "../utils/hooks/http.hook";

export const Template = () => {
    const params = useParams()
    let alias = params?.alias

    const {loading, request} = useHttp()

    const [content, setContent] = React.useState(null)

    React.useEffect(() => {
        const getAliasTemplate = async (alias) => {
            let res = await request(`/v1/stats/my-template-data/${alias}`, "post", {
                stat: {
                    fields: ["rep_ad_fail_rep", "rep_ad_monitor", "rep_ad_report", "rep_ad_good"]
                }
            })

            if (res) {
                setData(res?.map(item => ({...item, id: item.seller_id})))
            }
        }
        if (alias) {
            getAliasTemplate(alias)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alias])

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        switch (alias) {
            case "stat_webmaster": {
                setContent(<Users users={data} loading={loading}/>)
                break
            }
            case "stat_webmaster_group": {
                setContent(<Users users={data} loading={loading}/>)
                break
            }
            case "webmaster_flows": {
                setContent(<WebmasterFlows data={data}/>)
                break
            }
            default: {
                setContent(<Users users={data} loading={loading}/>)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, alias, loading])

    return <div>
        {content}
    </div>
}
