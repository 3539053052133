import React from "react";
import {render} from "react-dom";
import {MyApp} from "./App";
import * as serviceWorker from './serviceWorker';
import "./index.css";
import "./utils/elements/style.css";
import "./utils/css/reboot.css";
import "./utils/css/vars.css";

render(<MyApp/>, document.getElementById("app"));

serviceWorker.unregister();