import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider, useDispatch, useSelector} from "react-redux";
import {Error404} from "./pages/system/Error404";
import {getUserInfo, init} from "./redux/app-reducer";
import {Layout} from "./components/Layout";
import store from "./redux/store";
import {Loader} from "./utils/elements/Loader";
import "./App.css";
import "./utils/css/table.css";
import "./utils/css/bootstrap-grid.css";
import {presetGpnDefault, Theme} from "@consta/uikit/Theme";

const App = () => {

    const dispatch = useDispatch();

    const initialized = useSelector((state) => state.app.initialized);
    const isAuth = useSelector((state) => state.auth.isAuth);
    const {profile} = useSelector((state) => state.profile);

    React.useEffect(() => {
        dispatch(init());
    }, [dispatch]);

    React.useEffect(() => {
        if (!profile && isAuth) {
            dispatch(getUserInfo())
        }
    }, [profile, isAuth, dispatch]);

    if (!initialized) {
        return <Loader/>
    }

    return <React.Fragment>
        <Switch>
            <Route path="/" render={() => <Layout/>}/>
            <Route path="*" render={() => <Error404/>}/>
        </Switch>
    </React.Fragment>
};

export const MyApp = () => <React.Fragment>
    <BrowserRouter>
        <Theme preset={presetGpnDefault}>
            <Provider store={store}>
                <App/>
            </Provider>
        </Theme>
    </BrowserRouter>
</React.Fragment>